import axios from 'axios';
import Cookies from 'universal-cookie';
import { CartResponseInterface } from '../Interface/CartItemInterface';
import { UserDetailsInterface } from '../Interface/UserDetailsInterface';
import {
  WE_ACTIVATION_DETAILS_CLICKED_I,
  WE_ADDED_TO_CART_I,
  WE_ASK_USER_WEB_PUSH_PERMISSION_I,
  WE_BANNER_CLICKED_I,
  WE_BUY_NOW_CLICKED_I,
  WE_CART_DETAILS_OJBECT_I,
  WE_CART_PAGE_VIEWED_I,
  WE_CART_UPDATED_I,
  WE_CATEGORY_SELECTED_I,
  WE_CHECKOUT_EMAIL_I,
  WE_CHECKOUT_PAGE_VIEWED_I,
  WE_CURATORS_PICK_CLICKED_I,
  WE_CURRENCY_UPDATE_I,
  WE_DISCOUNT_CODE_APPLIED_I,
  WE_DISCOUNT_CODE_FAILED_I,
  WE_EXPLORE_BY_PRICE_I,
  WE_FOOTER_LINK_CLICKED_I,
  WE_GAMEPOINTS_CLICKED_I,
  WE_GIFT_CARD_CLICKED_I,
  WE_JACKPOT_DETAILS_I,
  WE_LANGUAGE_UPDATE_I,
  WE_LOGIN_METHOD_I,
  WE_MEMBERSHIP_CLICKED_I,
  WE_MOBILE_EXPLORE_I,
  WE_NOTIFY_OUT_OF_STOCK_I,
  WE_PAYMENT_FAILED_I,
  WE_PAYMENT_INITIATE_I,
  WE_PAYMENT_METHOD_SELECT_I,
  WE_PAYMENT_SUCCESS_I,
  WE_PDP_BANNERS_CLICKED_I,
  WE_PLATFORM_CLICKED_I,
  WE_PLAY_JACKPOT_CLICKED_I,
  WE_PRODUCT_KEY_COPIED_I,
  WE_PRODUCT_VIEWED_I,
  WE_REEDEEM_CLICKED_I,
  WE_REEDEM_KEY_CLICKED_I,
  WE_REMOVED_FROM_CART_I,
  WE_SEARCH_PRODUCT_CLICKED_I,
  WE_SEARCH_RESULTS_I,
  WE_SET_ANONYMOUS_CHECKOUT_IDENTITY_I,
  WE_STORE_PAGE_FILTERS_I,
  WE_SUBHEADER_OPTION_CLICKED_I,
  WE_THEME_TOGGLE_I,
  WE_USER_LOGIN_I,
  WE_USER_REGISTER_I,
  WE_VIEW_COLLECTION_CLICKED_I,
  WE_VIEW_COLLECTION_PRODUCT_CLICKED_I,
  WE_WISHLIST_ITEM_ADDED_I,
  WE_WISHLIST_ITEM_REMOVED_I,
} from '../Interface/WebEngageInterface';
import { apiConstants } from '../constants/apiConstants';
import { saveEventV3 } from './eventTracking';
import { getProductSkuFromSlug } from './getProductSkuFromSlug';
import { triggerPurchaseEvent } from './gtmPurchase';
import {
  deleteLocalStorage,
  getLocalStorageString,
  saveLocalStorage,
} from './localStorageUtils';
import {
  sendMergeRecombeeUserEvent,
  sendRecombeePurchaseInteractionEvent,
} from './recombee';

// export async function initializeCleverTap(): Promise<CleverTap> {
//   const clevertapModule = await import('clevertap-web-sdk');

//   clevertapModule.default.init(clevertapConstants.ACCOUNT_ID);
//   clevertapModule.default.privacy.push({ optOut: false });
//   clevertapModule.default.privacy.push({ useIP: false });
//   clevertapModule.default.setLogLevel(
//     process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 0 : 3
//   );
//   clevertapModule.default.spa = true;

//   return clevertapModule.default;
// }

export const triggerWebEngageEvent = () => {
  let windowObj = window as any;
  return windowObj.webengage;
};

const cookies = new Cookies();

// const amplifyConfig = {
//   Auth: {
//     identityPoolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID,
//     region: 'ap-south-1',
//   },
// };

// //Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
//   AWSPinpoint: {
//     appId: process.env.NEXT_PUBLIC_PINPOINT_PROJECT_ID,
//     region: 'ap-south-1',
//     mandatorySignIn: false,
//   },
// };

// Analytics.configure(analyticsConfig);

// const triggerWebEngageEvent = () => {
//   let windowObj = window as any;
//   return windowObj.webengage;
// };

interface SendEventDataInterface {
  name: string;
  attributes: any;
}
const SendEventData = async ({ name, attributes }: SendEventDataInterface) => {
  try {
    // fetch user details from local storage
    const userDetails = getLocalStorageString('user_data');
    const parsedUserDetails = userDetails ? JSON.parse(userDetails) : null;
    const getCheckoutEmail = getLocalStorageString('checkout-email');
    const getCheckoutCustId = getLocalStorageString('checkout-custId');
    const getCheckoutUserEnc = getLocalStorageString('checkout-user_enc');

    let userObject: any = {};

    if (parsedUserDetails) {
      userObject['cust_id'] = parsedUserDetails?.cust_id ?? '';
      userObject['email'] = parsedUserDetails?.email ?? '';
      userObject['user_enc'] = parsedUserDetails?.user_enc ?? '';
      userObject['dob'] = parsedUserDetails?.dob ?? '';
      userObject['phone'] = parsedUserDetails?.phone ?? '';
      userObject['first_name'] = parsedUserDetails?.first_name ?? '';
      userObject['last_name'] = parsedUserDetails?.last_name ?? '';
    }

    // capture event
    let capturePayload: any = {};
    capturePayload['eventName'] = name;
    capturePayload['eventData'] = attributes;
    capturePayload['uId'] = userObject['user_enc'] || getCheckoutUserEnc || '';

    if (
      name === 'Payment Initiate' ||
      name === 'Payment Success' ||
      name === 'Payment Failed' ||
      name === 'Payment Email Added'
    ) {
      //replace Payment word with Checkout
      capturePayload['eventName'] = name.replace('Payment', 'Checkout');

      await axios.post(
        apiConstants.frontend + '/api/capture/event',
        capturePayload,
        {
          withCredentials: true,
        }
      );
    }

    triggerWebEngageEvent().track(name, attributes);

    // for AWS pinpoint
    // if (parsedUserDetails) {
    //   Analytics.record({
    //     name: name,
    //     attributes: { ...attributes, ...userObject },
    //   });
    // } else {
    //   Analytics.record({
    //     name: name,
    //     attributes: { ...attributes },
    //   });
    // }
  } catch (error) {}
};

const markUserAsKnownForWE = async ({
  email,
  userEnc,
}: {
  email: string;
  userEnc: string;
}) => {
  try {
    let capturePayload: any = {};
    capturePayload['uId'] = userEnc;
    capturePayload['email'] = email;

    // await axios.post(
    //   apiConstants.frontendEvent + '/capture/user',
    //   capturePayload,
    //   {
    //     withCredentials: true,
    //   }
    // );
  } catch (error) {}
};

// const SendUserData = async () => {
//   try {
//     // fetch user details from local storage
//     const userDetails = getLocalStorageString('user_data');
//     const parsedUserDetails = userDetails ? JSON.parse(userDetails) : null;

//     let userObject: any = {};

//     if (parsedUserDetails) {
//       userObject['cust_id'] = parsedUserDetails?.cust_id ?? '';
//       userObject['email'] = parsedUserDetails?.email ?? '';
//       userObject['user_enc'] = parsedUserDetails?.user_enc ?? '';
//       userObject['dob'] = parsedUserDetails?.dob ?? '';
//       userObject['phone'] = parsedUserDetails?.phone ?? '';
//       userObject['first_name'] = parsedUserDetails?.first_name ?? '';
//       userObject['last_name'] = parsedUserDetails?.last_name ?? '';
//     }

//     if (!!userObject['user_enc']) {
//       sendMergeRecombeeUserEvent(parsedUserDetails['user_enc']);
//     }

//     // capture event
//     let capturePayload: any = {};

//     if (!!userObject['user_enc']) {
//       capturePayload['uId'] = userObject['user_enc'];
//     }
//     if (!!userObject['first_name']) {
//       capturePayload['firstName'] = userObject['first_name'];
//     }
//     if (!!userObject['last_name']) {
//       capturePayload['lastName'] = userObject['last_name'];
//     }
//     if (!!userObject['dob']) {
//       capturePayload['birthDate'] = userObject['dob'];
//     }
//     if (!!userObject['email']) {
//       capturePayload['email'] = userObject['email'];
//     }
//     if (!!userObject['phone']) {
//       capturePayload['phone'] = userObject['phone'];
//     }

//     // if (capturePayload['uId'] && capturePayload['email']) {
//     const response = await axios.post(
//       apiConstants.frontendEvent + '/capture/user',
//       capturePayload,
//       {
//         withCredentials: true,
//       }
//     );
//     // }

//     // for AWS pinpoint
//     // if (parsedUserDetails) {
//     //   Analytics.record({
//     //     name: name,
//     //     attributes: { ...attributes, ...userObject },
//     //   });
//     // } else {
//     //   Analytics.record({
//     //     name: name,
//     //     attributes: { ...attributes },
//     //   });
//     // }
//   } catch (error) {
//     console.log(error);
//   }
// };

export const WE_USER_LOGIN = (props: WE_USER_LOGIN_I, ct?: boolean) => {
  try {
    const eventName = 'Login';
    const attributes = {
      userId: props.id,
      email: props.email,
      anonymous: props.anonymous ?? false,
    };

    if (!props.anonymous && ct) {
      SendEventData({
        name: eventName,
        attributes,
      });
    }

    // ct &&
    //   ct.onUserLogin.push({
    //     Site: { Identity: props.id, Email: props.email },
    //   });
    triggerWebEngageEvent().user.login(props.id);
  } catch {}
};

export const WE_USER_REGISTER = (props: WE_USER_REGISTER_I, ct?: boolean) => {
  try {
    const eventName = 'Register';
    const attributes = {
      email: props.email,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_SOCIAL_LOGIN_INITIATE = (method: string) => {
  try {
    saveLocalStorage('we_sl', method);
  } catch {}
};

export const WE_USER_EVENT_LOGIN_METHOD_SELECT = (
  props: WE_LOGIN_METHOD_I,
  ct?: boolean
) => {
  try {
    const we_socialLogin = getLocalStorageString('we_sl');
    if (we_socialLogin) {
      let attributes = {
        method: props.method,
      };
      let eventName = 'Login Method';

      // ct && ct.event.push(eventName, attributes);
      // clevertap.event.push(eventName, attributes);
      SendEventData({
        name: eventName,
        attributes,
      });
      deleteLocalStorage('we_sl');
    }
  } catch {}
};

export const WE_USER_LOGOUT = (ct?: boolean) => {
  try {
    // ct && ct.event.push('Logout');
    triggerWebEngageEvent().user.logout();
    SendEventData({
      name: 'Logout',
      attributes: {},
    });
  } catch {}
};

export const WE_USER_EVENT_TRACK_USER = (
  user: UserDetailsInterface,
  ct?: boolean
) => {
  try {
    triggerWebEngageEvent().user.setAttribute('user_id', user.user_enc);
    triggerWebEngageEvent().user.setAttribute('we_email', user.email);
    triggerWebEngageEvent().user.setAttribute('user_enc', user.user_enc);
    user.dob &&
      triggerWebEngageEvent().user.setAttribute('we_birth_date', user.dob);
    user.phone &&
      triggerWebEngageEvent().user.setAttribute('we_phone', user.phone);
    user.first_name &&
      triggerWebEngageEvent().user.setAttribute(
        'we_first_name',
        user.first_name
      );
    user.last_name &&
      triggerWebEngageEvent().user.setAttribute('we_last_name', user.last_name);
    triggerWebEngageEvent().user.setAttribute('we_email_opt_in', true);

    // save user preferred language
    triggerWebEngageEvent().user.setAttribute(
      'language',
      cookies.get('language_bck') || 'en'
    );

    // ct &&
    //   ct.profile.push({
    //     Site: {
    //       Name: user?.fullName ?? undefined,
    //       Email: user.email,
    //       Phone: user?.phone ?? undefined,
    //       DOB: user?.dob ? new Date(user.dob) : undefined,
    //       // Identity: user.user_enc,
    //     },
    //   });
    // SendEventData({
    //   name: 'User Details',
    //   attributes: {},
    // });
    sendMergeRecombeeUserEvent(user.user_enc);
  } catch {}
};

export const WE_USER_HOME_PAGE_VIEWED = (ct?: boolean) => {
  try {
    // ct && ct.event.push('Home Page Viewed');
    SendEventData({
      name: 'Home Page Viewed',
      attributes: {},
    });
  } catch {}
};

// not used in webengage (for clevertap catalog cart mails)
// export const WE_USER_UPDATE_CART_PRODUCT_IDS = (
//   props: Array<CartItemInterface>,
//   ct?: boolean
// ) => {
//   try {
//     // save the product ids clevertap profile as Cart array Array<{productIds}>
//     let productIds: Array<string> = [];
//     props.forEach((item) => {
//       for (let i = 0; i < item.qty; i++) {
//         productIds.push(String(item.product.productId));
//       }
//     });
//     // console.log(productIds);
//     if (productIds.length > 0) {
//       ct &&
//         ct.profile.push({
//           Site: {
//             Cart: productIds,
//           },
//         });
//     } else {
//       ct && ct.removeValueForKey('Cart');
//     }
//   } catch (error) {}
// };
//
export const WE_USER_EVENT_ADDED_TO_CART = (
  props: WE_ADDED_TO_CART_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Added To Cart';
    let attributes = {
      currency: props.currency,
      discount: props.discount,
      imageUrl: props.imageUrl,
      productName: props.productName,
      pageUrl: props.pageUrl,
      platform: props.platform,
      productId: props.productId,
      productType: props.productType,
      productUrl: props.productUrl,
      basePrice: props.basePrice,
      countries: props.countries,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

//
export const WE_USER_EVENT_CART_UPDATED = (
  props: WE_CART_UPDATED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Cart Updated';
    let attributes = {
      productName: props.productName,
      imageUrl: props.imageUrl,
      pageUrl: props.pageUrl,
      platform: props.platform,
      productId: props.productId,
      productUrl: props.productUrl,
      basePrice: props.basePrice,
      updatedQty: props.updatedQty,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

//
export const WE_USER_EVENT_REMOVED_FROM_CART = (
  props: WE_REMOVED_FROM_CART_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Removed From Cart';
    let attributes = {
      imageUrl: props.imageUrl,
      platform: props.platform,
      pageUrl: props.pageUrl,
      productId: props.productId,
      productUrl: props.productUrl,
      basePrice: props.basePrice,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

//
export const WE_USER_EVENT_PRODUCT_VIEWED = (
  props: WE_PRODUCT_VIEWED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Product Viewed';
    let attributes = {
      platform: props.platform,
      category: props.category,
      currency: props.currency,
      discount: props.discount,
      imageUrl: props.imageUrl,
      productName: props.productName,
      pageUrl: props.pageUrl,
      productId: props.productId,
      productType: props.productType,
      productUrl: props.pageUrl,
      basePrice: props.basePrice,
      inStock: props.inStock,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

//
export const WE_USER_EVENT_WISHLIST_ITEM_ADDED = (
  props: WE_WISHLIST_ITEM_ADDED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Wishlist Item Added';
    let attributes = {
      categories: props.categories,
      imageUrl: props.imageUrl,
      platform: props.platform,
      productId: props.productId,
      productName: props.productName,
      productType: props.productType,
      productUrl: props.productUrl,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

//
export const WE_USER_EVENT_WISHLIST_ITEM_REMOVED = (
  props: WE_WISHLIST_ITEM_REMOVED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Wishlist Item Removed';
    let attributes = {
      categories: props.categories,
      imageUrl: props.imageUrl,
      platform: props.platform,
      productId: props.productId,
      productName: props.productName,
      productType: props.productType,
      productUrl: props.productUrl,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

//
export const WE_USER_EVENT_BANNER_CLICK = (
  props: WE_BANNER_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Banner Clicked';
    let attributes = {
      bannerName: props.bannerName,
      bannerUrl: props.bannerUrl,
      // category: props.category,
      // platform: props.platform,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_THEME_TOGGLE = (
  props: WE_THEME_TOGGLE_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Theme Toggle';
    let attributes = {
      previousTheme: props.previousTheme,
      currentTheme: props.currentTheme,
      pageUrl: props.pageUrl,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_VIEW_COLLECTION_CLICKED = (
  props: WE_VIEW_COLLECTION_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'View Collection Clicked';
    let attributes = {
      collectionName: props.collectionName,
      collectionLink: props.collectionLink,
      pageUrl: props.pageUrl,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_COLLECTION_PRODUCT_CLICKED = (
  props: WE_VIEW_COLLECTION_PRODUCT_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Collection Product Clicked';
    let attributes = {
      collectionName: props.collectionName,
      pageUrl: props.pageUrl,
      productName: props.productName,
      productSlug: props.productSlug,
      basePrice: props.basePrice,
      productPlatform: props.productPlatform,
      productImage: props.productImage,
      productRegion: props.productRegion,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_EXPLORE_BY_PRICE_CLICKED = (
  props: WE_EXPLORE_BY_PRICE_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Explore By Price Clicked';
    let attributes = {
      priceUnderWithCurrency: props.priceUnderWithCurrency,
      currency: props.currency,
      pageUrl: props.pageUrl,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_WE_PLATFORM_CLICKED = (
  props: WE_PLATFORM_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Platform Clicked';
    let attributes = {
      platformName: props.platformName,
      pageUrl: window.location.href,
      link: props.link,
      image: props.image,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_GIFTCARD_CLICKED = (
  props: WE_GIFT_CARD_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Giftcard Clicked';
    let attributes = {
      giftCardName: props.giftCardName,
      pageUrl: window.location.href,
      link: props.link,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENTS_SUBHEADER_OPTION_CLICKED = (
  props: WE_SUBHEADER_OPTION_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Subheader Option Clicked';
    let attributes = {
      pageUrl: props.pageUrl,
      option: props.option,
      link: props.link,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENTS_FOOTER_LINK_CLICKED = (
  props: WE_FOOTER_LINK_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Footer Link Clicked';
    let attributes = {
      pageUrl: props.pageUrl,
      link: props.link,
      footerOptionName: props.footerOptionName,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_LANGUAGE_UPDATE = (
  props: WE_LANGUAGE_UPDATE_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Language Updated';
    let attributes = {
      pageUrl: props.pageUrl,
      language: props.language,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_CURRENCY_UPDATE = (
  props: WE_CURRENCY_UPDATE_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Currency Updated';
    let attributes = {
      pageUrl: props.pageUrl,
      country: props.country,
      currency: props.currency,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_PDP_BANNERS_CLICKED = (
  props: WE_PDP_BANNERS_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'PDP Banner Clicked';
    let attributes = {
      bannerImage: props.bannerImage,
      productSlug: props.productSlug,
      pageUrl: props.pageUrl,
      link: props.link,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENTS_SEARCH_RESULTS = (
  props: WE_SEARCH_RESULTS_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Search Results';
    let attributes = {
      searchQuery: props.searchQuery,
      pageUrl: props.pageUrl,
      resultLink: props.resultLink,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENTS_SEARCH_PRODUCT_CLICKED = (
  props: WE_SEARCH_PRODUCT_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Search Product Clicked';
    let attributes = {
      searchQuery: props.searchQuery,
      productName: props.productName,
      productPlatform: props.productPlatform,
      basePrice: props.basePrice,
      productImage: props.productImage,
      productRegion: props.productRegion,
      pageUrl: props.pageUrl,
      currency: props.currency,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENTS_CATEGORY_CLICKED = (
  props: WE_CATEGORY_SELECTED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Category Clicked';
    let attributes = {
      categoryName: props.categoryName,
      pageUrl: props.pageUrl,
      link: props.link,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_MEMBERSHIP_CLICKED = (
  props: WE_MEMBERSHIP_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Membership Clicked';
    let attributes = {
      name: props.name,
      pageUrl: props.pageUrl,
      link: props.link,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_GAMEPOINTS_CLICKED = (
  props: WE_GAMEPOINTS_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Gamepoints Clicked';
    let attributes = {
      name: props.name,
      pageUrl: props.pageUrl,
      link: props.link,
      imageUrl: props.imageUrl,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_CURATORS_PICK_CLICKED = (
  props: WE_CURATORS_PICK_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = "Curator's Pick Clicked";
    let attributes = {
      productName: props.productName,
      productImage: props.productImage,
      pageUrl: props.pageUrl,
      productSlug: props.productSlug,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_DISCOUNT_CODE_APPLIED = (
  props: WE_DISCOUNT_CODE_APPLIED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Discount Code Applied';
    let attributes = {
      code: props.code,
      codeId: props.codeId,
      codeName: props.codeName,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_DISCOUNT_CODE_FAILED = (
  props: WE_DISCOUNT_CODE_FAILED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Discount Code Failed';
    let attributes = {
      code: props.code,
      failedMsg: props.failedMsg,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_STORE_PAGE_FILTERS_APPLIED = (
  props: WE_STORE_PAGE_FILTERS_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Store Page Filter Applied';
    let attributes = {
      productType: props.productType,
      priceMax: props.priceMax,
      priceMin: props.priceMin,
      region: props.region,
      platform: props.platform,
      genre: props.genre,
      language: props.language,
      sortBy: props.sortBy,
      excludeOutOfStock: props.excludeOutOfStock,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_NOTIFY_WHEN_IN_STOCK = (
  props: WE_NOTIFY_OUT_OF_STOCK_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Notify When in Stock';
    let attributes = {
      productSlug: props.productSlug,
      productRegion: props.productRegion,
      productPlatform: props.productPlatform,
      productImage: props.productImage,
      productTitle: props.productTitle,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_PAYMENT_METHOD_SELECT = (
  props: WE_PAYMENT_METHOD_SELECT_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Payment Method Selected';
    let attributes = {
      paymentMethod: props.paymentMethod,
      totalPrice: props.totalPrice,
      currency: props.currency,
      serviceCharge: props.serviceCharge,
      items: props.items,
      cartDetails: props.cartDetails,
    };

    //for clevertap remove array type events
    // let { cartDetails, ...rest } = attributes;

    // ct && ct.event.push(eventName, rest);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_SET_ANONYMOUS_CHECKOUT_IDENTITY = (
  props: WE_SET_ANONYMOUS_CHECKOUT_IDENTITY_I,
  ct?: boolean
) => {
  try {
    if (props.anonCheckout) {
      // set identity for anonymous checkout just before sending payment success event
      // ct &&
      //   ct.onUserLogin.push({
      //     Site: { Identity: props.user_enc },
      //   });
      markUserAsKnownForWE({
        email: props.user_email,
        userEnc: props.user_enc,
      });
      saveLocalStorage('we_pay_status', '1');
    }
  } catch (err) {}
};

export const WE_USER_EVENT_CHECKOUT_EMAIL = (
  props: WE_CHECKOUT_EMAIL_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Payment Email Added';
    let attributes = {
      email: props.email,
      anonCheckout: props.anonCheckout,
      user_enc: props.user_enc,
    };

    // without setting identity, user won't be identified in clevertap dashboard only the email will be set to clevertap profile
    // let getPastPaymentStatus = getLocalStorageString('we_pay_status');

    // if (!getPastPaymentStatus) {
    //   ct &&
    //     ct.profile.push({
    //       Site: { Email: props.email },
    //     });
    // } else if (getPastPaymentStatus !== props.email) {
    //   ct &&
    //     ct.onUserLogin.push({
    //       Site: { Identity: props.user_enc, Email: props.email },
    //     });
    //   deleteLocalStorage('we_pay_status');
    // }

    // ct && ct.event.push(eventName, attributes);

    // triggerWebEngageEvent().user.setAttribute('we_email', props.email);
    // clevertap.onUserLogin.push({
    //   Site: {
    //     Email: props.email,
    //     'User Enc': props.user_enc,
    //     AnonCheckout: props.anonCheckout,
    //   },
    // });
    triggerWebEngageEvent().user.setAttribute('we_email', props.email);
    triggerWebEngageEvent().user.setAttribute('we_user_enc', props.user_enc);

    // save user preferred language
    triggerWebEngageEvent().user.setAttribute(
      'language',
      cookies.get('language_bck') || 'en'
    );

    SendEventData({
      name: eventName,
      attributes,
    });
    sendMergeRecombeeUserEvent(props.user_enc);
  } catch {}
};

export const WE_USER_EVENT_CART_PAGE_VIEWED = (
  props: WE_CART_PAGE_VIEWED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Cart Page Viewed';
    let attributes = {
      items: props.items,
      cartDetails: props.cartDetails,
      currency: props.currency,
      cartTotal: props.cartTotal,
    };

    //for clevertap remove array type events
    // let { cartDetails, ...rest } = attributes;

    // ct && ct.event.push(eventName, rest);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_CHECKOUT_PAGE_VIEWED = (
  props: WE_CHECKOUT_PAGE_VIEWED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Checkout Page Viewed';
    let attributes = {
      items: props.items,
      cartDetails: props.cartDetails,
      currency: props.currency,
      cartTotal: props.cartTotal,
    };

    //for clevertap remove array type events
    // let { cartDetails, ...rest } = attributes;

    // ct && ct.event.push(eventName, rest);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_PAYMENT_INITIATE = (
  props: WE_PAYMENT_INITIATE_I,
  ct?: boolean
) => {
  try {
    saveLocalStorage('we_pi', '1');

    saveEventV3({
      action: 'load',
      category: props.cartType === 'marketplace' ? 'payment' : 'esim_payment',
      label: 'order_initiated',
      properties: String(props.cartTotal),
      value: [props.paymentMethod.toString() ?? ''],
      from: props.router,
      jsonData: {
        cart: props.cartDetails,
        paymentMethod: props.paymentMethod,
        cartType: props.cartType,
      },
    });

    // if (cookies.get('drif_acq') === 'facebook-ads') {
    try {
      if (props.cartType === 'marketplace') {
        // @ts-ignore
        fbq('track', 'InitiateCheckout', {
          value: props.cartTotal,
          currency: 'EUR',
          contents: props.cartDetails.map((item) => ({
            id: getProductSkuFromSlug(item.slug),
            quantity: item.quantity,
          })),
        });
      }
      if (props.cartType === 'eSim') {
        // @ts-ignore
        fbq('trackCustom', 'InitiateCheckout_ESim', {
          value: props.cartTotal,
          currency: 'EUR',
          contents: props.cartDetails.map((item) => ({
            id: getProductSkuFromSlug(item.slug),
            quantity: item.quantity,
          })),
        });
      }
    } catch (error) {}
    // }

    let eventName = 'Payment Initiate';
    let attributes = {
      cartTotal: props.cartTotal,
      paymentMethod: props.paymentMethod,
      items: props.items,
      cartDetails: props.cartDetails,
      email: props.customerEmail,
      cartType: props.cartType,
    };

    //for clevertap remove array type events
    // let { cartDetails, ...rest } = attributes;

    // ct && ct.event.push(eventName, rest);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_PAYMENT_FAILED = (
  props: WE_PAYMENT_FAILED_I,
  ct?: boolean
) => {
  try {
    let we_pi = getLocalStorageString('we_pi');
    if (we_pi === '1') {
      deleteLocalStorage('we_pi');
      saveEventV3({
        action: 'load',
        category: props.cartType === 'marketplace' ? 'payment' : 'esim_payment',
        label: 'order_failed',
        properties: String(props.cartTotal),
        value: [props.errorMsg ?? ''],
        from: props.router,
        jsonData: {
          // email: props.customerEmail,
          // cust_id: props.customerId,
          cart: props.cartDetails,
          paymentMethod: props.paymentMethod,
          cartType: props.cartType,
        },
      });

      let eventName = 'Payment Failed';
      let attributes = {
        errorMsg: props.errorMsg,
        items: props.items,
        cartDetails: props.cartDetails,
        paymentMethod: props.paymentMethod,
        cartTotal: props.cartTotal,
        email: props.customerEmail,
        cartType: props.cartType,
      };

      //for clevertap remove array type events
      // let { cartDetails, ...rest } = attributes;

      // ct && ct.event.push(eventName, rest);

      SendEventData({
        name: eventName,
        attributes,
      });
    }
  } catch {}
};

export const WE_USER_EVENT_PAYMENT_SUCCESS = (
  props: WE_PAYMENT_SUCCESS_I,
  ct?: boolean
) => {
  try {
    let we_pi = getLocalStorageString('we_pi');
    if (we_pi === '1') {
      if (props.anonCheckout) {
        // set identity for anonymous checkout just before sending payment success event
        // ct &&
        //   ct.onUserLogin.push({
        //     Site: { Identity: props.user_enc },
        //   });
        saveLocalStorage('we_pay_status', props.customerEmail);
      }

      if (props.orderDetails && props.orderDetails.items.length > 0) {
        try {
          triggerPurchaseEvent(props.orderDetails, props.cartType);
        } catch (error) {
          try {
            saveEventV3({
              action: 'load',
              category:
                props.cartType === 'marketplace' ? 'payment' : 'esim_payment',
              label: 'gtag_purchase',
              properties: 'failed',
              value: [props.orderId ?? ''],
              from: props.router,
              jsonData: {
                error: JSON.stringify(error),
                cartTotal: String(props.cartTotal),
                cartType: props.cartType,
              },
            });
          } catch (error) {
            saveEventV3({
              action: 'load',
              category:
                props.cartType === 'marketplace' ? 'payment' : 'esim_payment',
              label: 'gtag_purchase',
              properties: 'failed',
              value: [props.orderId ?? ''],
              from: props.router,
              jsonData: {
                error: 'error',
                cartTotal: String(props.cartTotal),
                cartType: props.cartType,
              },
            });
          }
        }
      }

      deleteLocalStorage('we_pi');
      saveEventV3({
        action: 'load',
        category: props.cartType === 'marketplace' ? 'payment' : 'esim_payment',
        label: 'order_success',
        properties: String(
          props.orderDetails?.orderAmountBase ?? props.cartTotal
        ),
        value: [props.orderId ?? ''],
        from: props.router,
        jsonData: {
          // email: props.customerEmail,
          // cust_id: props.customerId,
          cart: props.cartDetails,
          utmSource: props.utmSource,
          utmCampaign: props.utmCampaign,
          cartType: props.cartType,
        },
      });

      // recombee purchase event
      if (props.orderDetails && props.cartType === 'marketplace') {
        sendRecombeePurchaseInteractionEvent(props.orderDetails);
      }

      let eventName = 'Payment Success';
      let attributes = {
        orderId: props.orderId,
        status: 'success',
        items: props.items,
        cartDetails: props.cartDetails,
        cartTotal: props.cartTotal,
        email: props.customerEmail,
        utmSource: props.utmSource,
        utmCampaign: props.utmCampaign,
        paymentMethod: props.paymentMethod,
        cartType: props.cartType,
      };

      //for clevertap remove array type events
      // let { cartDetails, ...rest } = attributes;

      // ct && ct.event.push(eventName, rest);

      // CHARGED EVENT FOR CLEVERTAP
      // ct &&
      //   ct.event.push('Charged', {
      //     Amount: props.cartTotal,
      //     'Charged ID': props.orderId,
      //     Items: [
      //       ...props.cartDetails.map((item) => ({
      //         Slug: item.slug,
      //         Name: item.title,
      //         Price: item.price,
      //         Quantity: item.quantity,
      //         Platform: item.platform,
      //       })),
      //     ],
      //   });

      SendEventData({
        name: eventName,
        attributes,
      });
    }
  } catch {}
};

export const WE_USER_EVENT_EXPLORE_MOBILE_CLICKED = (
  props: WE_MOBILE_EXPLORE_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Explore Mobile Clicked';
    let attributes = {
      name: props.name,
      link: props.link,
      pageUrl: props.pageUrl,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_GET_CART_ITEMS = (cart: CartResponseInterface) => {
  let trackItems: Array<string> = [];
  try {
    cart?.cartItems?.forEach((item: any) => {
      trackItems.push(
        item.product.productId +
          ' [:] ' +
          item.product.title +
          ' [:] ' +
          item.product.productSlug +
          ' [:] ' +
          item.qty +
          ' [:] ' +
          item.price
      );
    });
  } catch {}
  return trackItems.join(' [|] ');
};

export const WE_GET_CART_DETAILS_OBJECT = (cart: CartResponseInterface) => {
  let trackItems: Array<WE_CART_DETAILS_OJBECT_I> = [];
  try {
    cart.cartItems.forEach((item) => {
      trackItems.push({
        id: item.product.productId,
        title: item.product.title,
        slug: item.product.productSlug,
        price: item.price,
        sellerName: item.merchant.name,
        offerId: item.offerId,
        image: item.product.image,
        platform: item.product.platform,
        quantity: item.qty,
        isAvailable: item.availability,
      });
    });
  } catch {}
  return trackItems;
};

export const WE_USER_EVENT_BUY_NOW_CLICKED = (
  props: WE_BUY_NOW_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Buy now Clicked';
    let attributes = {
      currency: props.currency,
      discount: props.discount,
      imageUrl: props.imageUrl,
      productName: props.productName,
      pageUrl: props.pageUrl,
      platform: props.platform,
      productId: props.productId,
      productType: props.productType,
      productUrl: props.productUrl,
      basePrice: props.basePrice,
      countries: props.countries,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_ACTIVATION_DETAILS_CLICKED = (
  props: WE_ACTIVATION_DETAILS_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Activation Details Clicked';
    let attributes = {
      productName: props.productName,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_REEDEEM_CLICKED = (
  props: WE_REEDEEM_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Redeem Products Clicked';
    let attributes = {
      orderId: props.orderId,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_REEDEEM_KEY_CLICKED = (
  props: WE_REEDEM_KEY_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Redeem Key Clicked';
    let attributes = {
      orderId: props.orderId,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_PRODUCT_KEY_COPIED = (
  props: WE_PRODUCT_KEY_COPIED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Product Key Copied';
    let attributes = {
      orderId: props.orderId,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_PLAY_JACKPOT_CLICKED = (
  props: WE_PLAY_JACKPOT_CLICKED_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Play Jackpot Clicked';
    let attributes = {
      orderId: props.orderId,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_USER_EVENT_PLAY_JACKPOT_DETAILS = (
  props: WE_JACKPOT_DETAILS_I,
  ct?: boolean
) => {
  try {
    let eventName = 'Play Jackpot Received';
    let attributes = {
      promoCode: props.promoCode,
      terms: props.terms,
      description: props.description,
      expiry: props.expiry,
      currency: props.currency,
    };

    // ct && ct.event.push(eventName, attributes);

    SendEventData({
      name: eventName,
      attributes,
    });
  } catch {}
};

export const WE_ASK_USER_WEB_PUSH_PERMISSION = (
  props: WE_ASK_USER_WEB_PUSH_PERMISSION_I,
  ct?: boolean
) => {
  // try {
  //   ct &&
  //     ct.notifications.push({
  //       // "apnsWebPushId":"<apple web push id>", //only for safari browser
  //       // "apnsWebPushServiceUrl":"<safari package service url>",//only for safari browser
  //       titleText: props.title,
  //       bodyText: props.description,
  //       okButtonText: 'Notify me!',
  //       rejectButtonText: 'No thanks',
  //       okButtonColor: '#416AFF',
  //       rejectCallback() {
  //         saveEventV3({
  //           action: 'click',
  //           category: 'web_push',
  //           label: 'ask_permission',
  //           properties: '',
  //           value: ['denied'],
  //           from: props.router,
  //           jsonData: {},
  //         });
  //       },
  //       okCallback() {
  //         Notification.requestPermission().then((permission) => {
  //           if (permission === 'granted') {
  //             saveEventV3({
  //               action: 'click',
  //               category: 'web_push',
  //               label: 'notification',
  //               properties: '',
  //               value: ['allow'],
  //               from: props.router,
  //               jsonData: {},
  //             });
  //           } else {
  //             saveEventV3({
  //               action: 'click',
  //               category: 'web_push',
  //               label: 'notification',
  //               properties: '',
  //               value: ['block'],
  //               from: props.router,
  //               jsonData: {},
  //             });
  //           }
  //         });
  //       },
  //       askAgainTimeInSeconds: 60 * 60 * 24, // 1 day
  //     });
  // } catch {}
};
