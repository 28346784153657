import { useEffect, useState } from 'react';

function useTrustpilotScript() {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    // Load the Trustpilot script
    const script = document.createElement('script');
    script.src =
      'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
    script.onload = () => {
      setIsScriptLoaded(true); // Set state when script is loaded
    };
    document.body.appendChild(script);

    return () => {
      // Clean up the script element when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return {
    isScriptLoaded,
  };
}

export default useTrustpilotScript;
