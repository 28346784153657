var slugify = require('slugify');

import crypto from 'crypto';
import Cookies from 'universal-cookie';
import { FilterResponse } from '../Interface/FiltersInterface';
import {
  LocationDetailsInterface,
  RegionInterface,
} from '../Interface/GlobalInterface';
import {
  getLocalStorageExp,
  getLocalStorageString,
} from '../utils/localStorageUtils';
import { formatNumber } from '../utils/sellerStatsUtils';
const cookies = new Cookies();

export const createQuery = (queryFilters = {}, url = '/store?') => {
  // console.log('queryFilters', queryFilters);
  if ((queryFilters as any)?.priceMax === 'NaN') {
    (queryFilters as any)['priceMax'] = '';
  }

  if ((queryFilters as any)?.priceMin === 'NaN') {
    (queryFilters as any)['priceMin'] = '';
  }

  let param = new URLSearchParams(getEntries(queryFilters)).toString();
  if (param) url += param;

  return decodeURIComponent(url);
};

function getEntries(o = {}): Array<any> {
  const entries = [];
  for (const [k, v] of Object.entries(o)) {
    if (Array.isArray(v)) {
      //   entries.push(...v.flatMap(getEntries));
      v.map((val) => {
        entries.push([`${k}[]`, val]);
      });
    } else if (typeof v === 'object' && v != null) {
      entries.push(...getEntries(v));
    } else {
      entries.push([k, v]);
    }
  }
  return entries;
}

export const displayNumber = (
  number: number | null | string
): number | string => {
  let x = Number(number);
  if (typeof x === 'number') {
    return x.toFixed(2);
  } else {
    return 0;
  }
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password: string) => {
  if (password.length < 8) {
    return false;
  }
  return true;
};

export const checkDisplayValue = (
  value: string | number | null | undefined
) => {
  if (value) {
    return value;
  }
  return '';
};

export const htmlDecode = (input: string) => {
  let e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0
    ? ''
    : e?.childNodes[0]?.nodeValue
    ? e.childNodes[0].nodeValue
    : '';
};

export const createBaseQueryFilter = (query: any) => {
  if (!query) {
    return;
  }
  let x: any = {}; //ak-TODO
  if (query['genre[]']) {
    if (Array.isArray(query['genre[]'])) {
      x['genre'] = query['genre[]'];
    } else {
      x['genre'] = [query['genre[]']];
    }
  }
  if (query['platform[]']) {
    if (Array.isArray(query['platform[]'])) {
      x['platform'] = query['platform[]'];
    } else {
      x['platform'] = [query['platform[]']];
    }
  }
  if (query['productType[]']) {
    if (Array.isArray(query['productType[]'])) {
      x['productType'] = query['productType[]'];
    } else {
      x['productType'] = [query['productType[]']];
    }
  }
  if (query['lang[]']) {
    if (Array.isArray(query['lang[]'])) {
      x['lang'] = query['lang[]'];
    } else {
      x['lang'] = [query['lang[]']];
    }
  }
  if (query['region[]']) {
    if (Array.isArray(query['region[]'])) {
      x['region'] = query['region[]'];
    } else {
      x['region'] = [query['region[]']];
    }
  }
  if (query['worksOn[]']) {
    if (Array.isArray(query['worksOn[]'])) {
      x['worksOn'] = query['worksOn[]'];
    } else {
      x['worksOn'] = [query['worksOn[]']];
    }
  }
  return x;
};

//ak-TODO
export const createQueryFilter = (
  query: any,
  filters: FilterResponse | null
) => {
  if (!query) {
    return;
  }
  let x: any = {}; //ak-TODO
  if (query['genre[]']) {
    if (Array.isArray(query['genre[]'])) {
      x['genre'] = query['genre[]'];
    } else {
      x['genre'] = [query['genre[]']];
    }
  }
  if (query['platform[]']) {
    if (Array.isArray(query['platform[]'])) {
      x['platform'] = query['platform[]'];
    } else {
      x['platform'] = [query['platform[]']];
    }
  }
  if (query['productType[]']) {
    if (Array.isArray(query['productType[]'])) {
      x['productType'] = query['productType[]'];
    } else {
      x['productType'] = [query['productType[]']];
    }
  }
  if (query['lang[]']) {
    if (Array.isArray(query['lang[]'])) {
      x['lang'] = query['lang[]'];
    } else {
      x['lang'] = [query['lang[]']];
    }
  }
  if (query['region[]']) {
    if (Array.isArray(query['region[]'])) {
      x['region'] = query['region[]'];
    } else {
      x['region'] = [query['region[]']];
    }
  }
  if (query['worksOn[]']) {
    if (Array.isArray(query['worksOn[]'])) {
      x['worksOn'] = query['worksOn[]'];
    } else {
      x['worksOn'] = [query['worksOn[]']];
    }
  }
  if (query['page']) {
    x['page'] = query['page'];
  }
  if (query['eoos']) {
    x['eoos'] = query['eoos'];
  }

  if (query['preOrder']) {
    x['preOrder'] = query['preOrder'];
  }

  if (query['worksIn']) {
    x['worksIn'] = query['worksIn'];
  } else {
    x['worksIn'] = 'true';
  }

  if (query['q']) {
    x['q'] = query['q'];
  }
  if (query['priceMax']) {
    x['priceMax'] = query['priceMax'];
  }
  if (query['priceMin']) {
    x['priceMin'] = query['priceMin'];
  }
  if (query['sort']) {
    x['sort'] = query['sort'];
  }

  if (query['slug']) {
    x['vendor'] = query['slug'];
  }

  return getFilterForCategory(x, query, filters); //for specific product type pages
};

const getFilterForCategory = (
  x: any,
  query: any,
  filters: FilterResponse | null
) => {
  const type = query['type'];
  const value = query['value'];

  let catVal = null;
  catVal = findValueOfCategorySlug(type, value, filters);

  if (typeof type === 'string' && typeof value === 'string' && filters) {
    if (!catVal) {
      return null;
    }

    /**
     * ak-TODO
     * improve this code
     */
    // if (Array.isArray(query[`${type}[]`])) {
    //   if (x[type].indexOf(catVal) < 0) {
    //     x[type].push(catVal);
    //   }
    // } else {
    //   if (
    //     typeof query[`${type}[]`] === 'string' &&
    //     query[`${type}[]`] !== catVal
    //   ) {
    //     x[type] = [query[`${type}[]`], catVal];
    //   } else {
    //     x[type] = [catVal];
    //   }
    // }

    switch (type) {
      case 'genre':
        if (Array.isArray(query['genre[]'])) {
          if (x['genre'].indexOf(catVal) < 0) {
            x['genre'].push(catVal);
          }
        } else {
          if (
            typeof query['genre[]'] === 'string' &&
            query['genre[]'] !== catVal
          ) {
            x['genre'] = [query['genre[]'], catVal];
          } else {
            x['genre'] = [catVal];
          }
        }
        break;
      case 'platform':
        if (Array.isArray(query['platform[]'])) {
          if (x['platform'].indexOf(catVal) < 0) {
            x['platform'].push(catVal);
          }
        } else {
          if (typeof query['platform[]'] === 'string') {
            x['platform'] = [query['platform[]'], catVal];
          } else {
            x['platform'] = [catVal];
          }
        }
        break;
      case 'type':
        if (Array.isArray(query['productType[]'])) {
          if (x['productType'].indexOf(catVal) < 0) {
            x['productType'].push(catVal);
          }
        } else {
          if (typeof query['productType[]'] === 'string') {
            x['productType'] = [query['productType[]'], catVal];
          } else {
            x['productType'] = [catVal];
          }
        }
        break;
      default:
        x = null;
    }
  }
  return x;
};

export const formatQueryFilterV2 = (query: any) => {
  let x: any = {};
  if (query['productType']) {
    x['productType[]'] = query['productType'];
  }
  if (query['genre']) {
    x['genre[]'] = query['genre'];
  }

  if (query['platform']) {
    x['platform[]'] = query['platform'];
  }
  if (query['region']) {
    x['region[]'] = query['region'];
  }
  if (query['worksOn']) {
    x['worksOn[]'] = query['worksOn'];
  }
  if (query['lang']) {
    x['lang[]'] = query['lang'];
  }

  return x;
};

export const findValueOfCategorySlug = (
  category: 'genre' | 'platform' | 'type',
  text: string | number,
  filters: FilterResponse | null
) => {
  // console.log(category, text);
  let cat: 'genre' | 'platform' | 'productType' | null = null;
  if (category === 'type') {
    cat = 'productType';
  } else {
    cat = category;
  }

  if (filters && cat && text) {
    let ind = filters[cat].find((g) => convertToSlug(g.value) === text);
    if (ind) {
      return ind.value;
    }
  }
  return null;
};

export function convertToSlug(text: string | number) {
  if (typeof text === 'string') {
    const x = slugify(text.toLowerCase(), { strict: true });
    return x;
  }
}

export const displayCurrencyValue = (
  value: number | string | null | undefined,
  currency: string | null | undefined,
  multiplier: number | null | undefined
) => {
  if (!multiplier) {
    return 'NaN';
  }
  const x = (Number(value) * multiplier).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  if (!value) {
    return x;
  }
  let s = 'EUR';
  if (currency) {
    s = currency;
  }
  return s + x;
};

export const displayCurrencyValueESim = (
  value: number | string | null | undefined,
  currency: string | null | undefined,
  multiplier: number | null | undefined
) => {
  if (!multiplier) {
    return 'NaN';
  }
  const x = (Number(value) * multiplier).toLocaleString('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  if (!value) {
    return x;
  }
  let s = '$';
  // if (currency) {
  //   s = currency;
  // }
  return s + x;
};

export const getAbsoluteCurrencyValue = (
  value: number | string | null | undefined,
  multiplier: number | null | undefined
) => {
  if (!multiplier) {
    return 'NaN';
  }
  const x = (Number(value) * multiplier).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  if (!value) {
    return x;
  }
  return x;
};

/*
    Use this function to display strings in sentence case which were originally in camel case
    This is used to displaying keys like ownerInfo
*/
export function camelCaseToSentenceCase(val: string | null | undefined) {
  if (typeof val === 'string') {
    var result = val.replace(/([A-Z])/g, ' $1');
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  } else {
    return '';
  }
}

export function formatFilterTitle(val: string) {
  switch (val) {
    case 'psn':
      return 'PSN';
    case 'ios':
      return 'iOS';
    case 'dlc':
      return 'DLC';
    case 'xbox one':
      return 'Xbox One';
    case 'tps':
      return 'TPS';
    case 'rpg':
      return 'RPG';
    case 'row':
      return 'ROW';
    case 'xbox series x|s':
    case 'xbox_series_x|s':
      return 'Xbox Series X|S';
    case 'nintendo switch':
      return 'Nintendo Switch';
    case 'ea':
      return 'EA';
    case 'giftcard':
      return 'Gift card';
    default:
      return val.includes('_')
        ? val
            .split('_')
            .map((cur) => cur.charAt(0).toUpperCase() + cur.slice(1))
            .join(' ')
        : val.charAt(0).toUpperCase() + val.slice(1);
  }
}

export const getRegionFromRegionId = (regionId: string | null | undefined) => {
  if (typeof window !== 'undefined') {
    let regString = getLocalStorageExp('regionsArray');
    if (regionId && regString && typeof regString === 'string') {
      let regions = JSON.parse(regString);
      if (Array.isArray(regions)) {
        let region = regions.find((x) => x.regionId == regionId);
        if (region && typeof region.regionName === 'string') {
          return region.regionName;
        }
      }
    }
    return '-';
  } else {
    return '-';
  }
};

export const getRegionNameFromRegionId = (
  regionId: string | null | undefined,
  regions: RegionInterface[]
) => {
  if (typeof window !== 'undefined') {
    if (regionId && regions) {
      if (Array.isArray(regions)) {
        let region = regions.find((x) => String(x.regionId) == regionId);
        if (region) {
          return region.regionName;
        }
      }
    }
    return '-';
  } else {
    return '-';
  }
};

export const getCountryNameFromCountryCode = (
  countryCode: string | null | undefined
) => {
  if (typeof window !== 'undefined') {
    let regString = getLocalStorageExp('countryArray');
    if (countryCode && regString && typeof regString === 'string') {
      let countries = JSON.parse(regString);
      if (Array.isArray(countries)) {
        let country = countries.find((x) => x.country_code == countryCode);
        if (country && typeof country.country_name === 'string') {
          return country.country_name;
        }
      }
    }
    return '-';
  } else {
    return '-';
  }
};

export function removeTags(str: string | null | undefined): string {
  if (str === null || str === '' || !str) return '';
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, '');
}

export const getPercentageFromSellerName = (
  sellerName: string | null | undefined
) => {
  if (typeof sellerName === 'string' && sellerName.length >= 1)
    return Number(sellerName[0].charCodeAt(0).toString().slice(-1)) + 90;
  else return 98;
};

export function getFilterList(multiplier: number) {
  const list = [
    0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000,
    20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000, 10000000,
    20000000, 50000000, 100000000, 200000000, 500000000,
  ];
  let min = 0;
  let max = 0;
  let index = 0;
  for (let i = 0; i < list.length - 1; i++) {
    if (multiplier >= list[i] && multiplier <= list[i + 1]) {
      min = list[i];
      max = list[i + 1];
      index = i;
      break;
    }
  }
  let start = min;
  if (multiplier > (max - min) / 3 + min) {
    start = max;
    index += 1;
  }
  let genList = [];
  for (let i = index; i < index + 6; i++) {
    genList.push(list[i]);
  }
  return genList;
}

export const shuffleArr = (arr: any[]) => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

export function MyOrderStatus() {
  const orderMap = new Map();
  orderMap.set('s', 'Success');
  orderMap.set('f', 'Failed');
  orderMap.set('p', 'Processing');
  orderMap.set('RP', 'Refunded');
  orderMap.set('c', 'Cancelled');
  return orderMap;
}

export const mergeFacetsDistribution = (
  initialFacetsDistribution: any,
  updatedFacetsDistribution: any
) => {
  const newFacetsDistribution: any = {};
  for (const key in initialFacetsDistribution) {
    if (Object.prototype.hasOwnProperty.call(initialFacetsDistribution, key)) {
      const newValues = [];
      const initialValues = initialFacetsDistribution[key];
      const updatedValues = updatedFacetsDistribution[key];

      for (let i = 0; i < initialValues.length; i++) {
        const initialValue = initialValues[i];
        let updatedValue = updatedValues.find(
          (val: any) => val.value === initialValue.value
        );

        if (updatedValue) {
          updatedValue = { ...updatedValue };
          newValues.push(updatedValue);
        } else {
          const zeroValue = { ...initialValue, count: 0 };
          newValues.push(zeroValue);
        }
      }

      newFacetsDistribution[key] = newValues;
    }
  }

  return newFacetsDistribution;
};

export const isValidDate = (dateString: string) => {
  // Create a new Date object from the input string
  const date = new Date(dateString);

  // Check if the date object is valid
  if (Object.prototype.toString.call(date) === '[object Date]') {
    // Invalid date objects return "Invalid Date"
    if (isNaN(date.getTime())) {
      return false;
    }
    // Valid date objects
    else {
      return true;
    }
  }
  return false;
};

// const deviceIdPattern =
//   /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

export const checkNsureSDK = () => {
  try {
    // let nsureId = '';
    let isNsureAvailable = (window as any).nSureSDK.version;
    if (isNsureAvailable) {
      // nsureId = (window as any).nSureSDK.getDeviceId();
      // console.log(nsureId);
      // if (!deviceIdPattern.test(nsureId)) {
      //   setDisplaySiteAlert(true);
      // } else {
      //   setDisplaySiteAlert(false);
      // }
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getDiscount = (
  mrp: string | number | null,
  price: string | number | null
) => {
  // let discount =
  //   Number(mrp) > 0 && Number(mrp) > Number(price)
  //     ? Math.round(((Number(mrp) - Number(price)) * 100) / Number(mrp))
  //     : 0;

  // return discount;
  if (Number(mrp) > Number(price)) {
    const calculatedDiscount = Math.round(
      ((Number(mrp) - Number(price)) * 100) / Number(mrp)
    );

    if (calculatedDiscount > 0) {
      if (calculatedDiscount > 99) {
        return 99;
      } else {
        return calculatedDiscount;
      }
    } else {
      return 1; // Return at least 1% discount
    }
  }

  return null;
};

export const formatDateToLongFormat = (dateStr: string) => {
  const dateObj = new Date(dateStr);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const d = dateObj.getDate();
  const m = dateObj.getMonth();
  const y = dateObj.getFullYear();

  return `${months[m]} ${d}, ${y}`;
};

export const getCookieDomain = () => {
  let domain = '.driffle.com';
  if (String(process.env.NEXT_PUBLIC_APP_ENV).includes('development')) {
    domain = '.dev.driffle.com';
  } else if (String(process.env.NEXT_PUBLIC_APP_ENV).includes('staging')) {
    domain = '.driffle.dev';
  } else if (String(process.env.NEXT_PUBLIC_APP_ENV).includes('dev')) {
    domain = 'localhost';
  }
  return domain;
};

export const getDomainBasedOnEnvironment = () => {
  let domain = 'https://driffle.com';
  domain =
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? 'https://driffle.com'
      : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
      ? 'https://driffle.dev'
      : process.env.NEXT_PUBLIC_APP_ENV === 'development'
      ? 'https://web.dev.driffle.com'
      : 'http://localhost:4001';
  return domain;
};

export const displayApproximateValue = (value: number) => {
  // If the value is less than 100, return 'Less than 100'
  if (value < 100) return 'Less than 100';

  // Find the most significant digit
  let mostSignificantDigit = 1;
  let divisor = 1;
  while (value >= 10) {
    value /= 10;
    mostSignificantDigit *= 10;
    divisor *= 10;
  }

  // Calculate the lower bound of the hundred range the value is in,
  // convert it to string and append '+'
  const lowerBound = Math.floor(value) * divisor;
  return formatNumber(lowerBound) + '+';
};

export const getSumOfNumericalValuesFromString = (
  inputString: string
): number => {
  const regex = /\d+(\.\d+)?/g; // Matches integers and decimal numbers
  const matches = inputString.match(regex);

  if (matches) {
    return matches.reduce((sum, match) => {
      if (match.includes('.')) {
        // If the match contains a decimal point, parse it as a floating-point number
        return sum + parseFloat(match);
      } else {
        // Otherwise, parse it as an integer
        return sum + parseInt(match, 10);
      }
    }, 0);
  } else {
    return 0;
  }
};

export function moveMatchingObjectToFirst(array: any, targetString: string) {
  const index = array.findIndex((obj: any) => obj.title.includes(targetString));

  if (index !== -1) {
    const matchingObj = array[index];
    array.splice(index, 1);
    array.unshift(matchingObj);
  }
  return array;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const isValidUrl = (url: string) => {
  // Check if URL length is within the limit
  if (url.length > 512) {
    return false;
  }

  // Check for presence of http or https
  if (/^(http:|https:)/.test(url)) {
    return false;
  }

  // Check for IP address format
  if (/^(\d{1,3}\.){3}\d{1,3}$/.test(url)) {
    return false;
  }

  // Check for query strings
  if (/\?.+=/.test(url)) {
    return false;
  }

  // Check for a valid domain name structure
  // if (
  //   !/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(url)
  // ) {
  //   // This regex checks for a general pattern of domain names,
  //   // it starts with alphanumeric characters, contains middle part which may have hyphens but not at the beginning or end,
  //   // and ends with a dot followed by 2 or more alphabetic characters for the TLD.
  //   // Adjust according to your specific domain name needs.
  //   return false;
  // }

  return true;
};

export const getCountryList = (): Array<LocationDetailsInterface> => {
  if (typeof window !== 'undefined') {
    const countString = getLocalStorageString('countryArray');
    if (countString) {
      const countArr: Array<LocationDetailsInterface> = JSON.parse(
        JSON.parse(countString).value
      );
      return countArr;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const calculateDaysPassed = (dateObj: Date) => {
  const today = new Date();
  let timeDifference = dateObj.getTime() - today.getTime();
  let dayMilliSeconds = 1000 * 60 * 60 * 24;
  let totalDays = Math.abs(timeDifference / dayMilliSeconds);
  totalDays = Math.floor(totalDays);
  return totalDays;
};

export const file2Base64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => reject(error);
  });
};

export const hashEmail = (email: string) => {
  // Normalize the email
  const normalizedEmail = email.trim().toLowerCase();

  // Create MD5 hash
  const md5Hash = crypto
    .createHash('md5')
    .update(normalizedEmail, 'utf8')
    .digest('hex');

  // Create SHA256 hash
  const sha256Hash = crypto
    .createHash('sha256')
    .update(normalizedEmail, 'utf8')
    .digest('hex');

  return { md5: md5Hash, sha256: sha256Hash };
};

export const setRandomInterval = (
  intervalFunction: () => void,
  minDelay: number,
  maxDelay: number
) => {
  let timeout: NodeJS.Timeout;

  const runInterval = () => {
    const timeoutFunction = () => {
      intervalFunction();
      runInterval();
    };

    const delay =
      Math.floor(Math.random() * (maxDelay - minDelay + 1)) + minDelay;

    timeout = setTimeout(timeoutFunction, delay);
  };

  runInterval();

  return {
    clear() {
      clearTimeout(timeout);
    },
  };
};

export const getIconFromPlatformName = (name: string | null | undefined) => {
  if (typeof window !== 'undefined') {
    let regString = getLocalStorageExp('platformsArray');

    if (name && regString) {
      let platforms = JSON.parse(regString);
      if (Array.isArray(platforms)) {
        let platform = platforms.find((x) => x.name == name);
        if (platform && typeof platform.name === 'string') {
          return platform.icon;
        }
      }
    }
    return '/icons/platform_icons/other.svg';
  } else {
    return '/icons/platform_icons/other.svg';
  }
};

export function isTouchDevice() {
  if (typeof window !== 'undefined') {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }
  return false; // Default to false on server-side rendering
}
