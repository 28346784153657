export const MAX_YOU_GET_PRICE = 3000;

export enum TICKET_STATUS {
  DELETED = -1,
  DISABLED = 0,
  OPEN = 1,
  PENDING = 2,
  RESOLVED = 3,
  CLOSED = 4,
  WAITING_ON_CUSTOMER = 5,
  WAITING_ON_SELLER = 6,
  WAITING_ON_DRIFFLE = 7,
  ATTENTION_REQUIRED = 8,
  FIRST_RESPONSE_DUE = 9,
  REFUND_INITIATED = 10,
  REPLACEMENT_INITIATED = 11,
}

export const deListCountries = [
  'RU',
  'AF',
  'CU',
  'IR',
  'SD',
  'YE',
  'IQ',
  'SY',
  'VE',
];

export enum CART_TYPE {
  ESIM = 'ESIM',
  DRIFFLE = 'DRIFFLE',
}
