import { createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { NextRouter } from 'next/router';
import {
  CartItemInterface,
  CartResponseInterface,
} from '../Interface/CartItemInterface';
import { LocationDetailsInterface } from '../Interface/GlobalInterface';
import { GtagItemInterface } from '../Interface/GtagInterface';
import {
  ProductDetailsInterface,
  ProductOfferInterface,
} from '../Interface/ProductDetailsInterface';
import { apiConstants } from '../constants/apiConstants';
import { getSubscriptionABTestMethods } from '../utils/abTest';
import { saveEventV3, trackEvent } from '../utils/eventTracking';
import { getProductSkuFromSlug } from '../utils/getProductSkuFromSlug';
import { sendRecombeeAddToCartEvent } from '../utils/recombee';
import {
  WE_USER_EVENT_DISCOUNT_CODE_APPLIED,
  WE_USER_EVENT_DISCOUNT_CODE_FAILED,
} from '../utils/we';

interface InitialStateInterface extends CartResponseInterface {
  isFetching: 'fetching' | 'fetched' | 'not-fetched';
  paymentSession: null | string;
}
// Define the initial state using that type
export const cartInitialState: InitialStateInterface = {
  cartId: null,
  cartItems: [],
  subTotal: null,
  serviceFee: null,
  total: null,
  paymentMethod: 1,
  promo: null,
  isFetching: 'not-fetched',
  paymentSession: null,
  subscription: false,
  drifflePlusPrice: {
    serviceFee: null,
    subTotal: null,
    total: null,
  },
};

export const fetchCarts = (isEsim?: boolean) => async (dispatch: any) => {
  dispatch(fetchingCart());
  let cart = {
    cartId: null,
    cartItems: [],
    subTotal: null,
    serviceFee: null,
    total: null,
    subscription: false,
  };
  await axios
    .get(
      apiConstants.server + (isEsim ? '/public/cart/esim' : '/public/cart/'),
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      cart = res.data;
      if (isEsim) {
        cart = res.data.data;
      }
    })
    .catch((err: AxiosError) => {
      dispatch(saveCart(cartInitialState));
      throw Error(err?.response?.data?.msg ?? 'error fetching cart');
    });
  await dispatch(saveCart(cart));
  // return cart;
};

export const updatePaymentMethod =
  (id: number, isEsim?: boolean) => async (dispatch: any) => {
    await axios
      .post(
        apiConstants.server +
          (isEsim
            ? '/public/cart/esim/set-payment-method'
            : '/public/cart/set-payment-method'),
        {
          pmId: id,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {})
      .catch((err: AxiosError) => {
        throw Error(err.response?.data?.msg);
      });
    await dispatch(fetchCarts(isEsim)).catch(() => {
      throw Error('error fetching cart');
    });
  };

interface CartType {
  offerId: number;
  qty: number;
  action: 'add' | 'remove';
  slug: string;
}

export const updateCart =
  ({ offerId, qty, action, slug }: CartType) =>
  async (dispatch: any) => {
    try {
      await axios
        .post(
          apiConstants.server + '/public/cart/update-quantity',
          {
            offerId,
            qty,
            action,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          try {
            let sku = getProductSkuFromSlug(slug);
            if (sku && action === 'remove' && qty === 0) {
              sendRecombeeAddToCartEvent('remove', qty, sku);
            }
          } catch (e) {}
        });
      await dispatch(fetchCarts());
    } catch (err: any) {
      throw Error(err.response?.data?.msg);
    }
  };

interface AddCartType {
  offerId: number;
  haveSubscriptionInCart?: boolean;
  cartItems: Array<CartItemInterface>;
  product?: ProductDetailsInterface;
  locationDetails?: LocationDetailsInterface;
  offers?: Array<ProductOfferInterface>;
  qty: number;
  router?: NextRouter;
  price?: number;
  productSku?: number;
}

export const addToCart =
  (
    {
      offerId,
      haveSubscriptionInCart = false,
      cartItems,
      product,
      locationDetails,
      offers,
      qty,
      router,
      price,
      productSku,
    }: AddCartType,
    isEsim?: boolean
  ) =>
  async (dispatch: any) => {
    let updatedQuantity = qty;
    let present = false;

    if (!isEsim) {
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].offerId === offerId) {
          updatedQuantity = cartItems[i].qty + qty;
          present = true;
        }
      }
    }

    const subRoute = present
      ? '/public/cart/update-quantity'
      : isEsim
      ? '/public/cart/esim/add-to-cart'
      : '/public/cart/add-to-cart';

    await axios
      .post(
        apiConstants.server + subRoute,
        {
          offerId,
          haveSubscriptionInCart,
          qty: updatedQuantity,
          productSku: product?.productSku ?? productSku,
        },
        { withCredentials: true }
      )
      .then((response) => {
        try {
          if (!isEsim) {
            sendRecombeeAddToCartEvent(
              'add',
              qty,
              // @ts-ignore
              product?.productSku ?? productSku,
              product?.offers?.filter((el) => el.offerId === offerId)[0]
                .price || price
            );

            let itemsArray: Array<GtagItemInterface> = [
              {
                item_id: (product?.productSku ?? productSku)!,
                item_name: product?.name || '',
                google_business_vertical: 'retail',
                item_brand: product?.publishers[0],
                item_category: product?.productTypes[0],
                // item_category2: product.platform,
                // price:
                //   offers.filter((el) => el.offerId === offerId)[0].price *
                //     (locationDetails?.multiplier || 1) || 1,
                // currency: locationDetails.currency || 'EUR',
                price:
                  offers?.filter((el) => el.offerId === offerId)[0].price ||
                  String(price),
                currency: 'EUR',
                basePrice: offers?.filter((el) => el.offerId === offerId)[0]
                  .price,
                quantity: qty,
              },
            ];

            const fbObjectAddToCart = [
              {
                id: product?.productSku ?? productSku,
                quantity: qty,
                name: product?.name,
              },
            ];
            const eventData = {
              event: 'add_to_cart',
              ecommerce: {
                // value: offers[0].price * (locationDetails?.multiplier || 1),
                // currency: locationDetails.currency || 'EUR',
                value:
                  offers?.filter((el) => el.offerId === offerId)[0].price ||
                  price,
                currency: 'EUR',
                items: itemsArray,
                fbObjectAddToCart,
                // fbObject: [{id}, {}]
              },
            };
            trackEvent('gtm_event', { eventData });

            // meta/fb pixel event for add to cart
            // if (cookies.get('drif_acq') === 'facebook-ads') {
            try {
              //@ts-ignore
              fbq('track', 'AddToCart', {
                content_ids: [product?.productSku ?? productSku],
                content_name: product?.name,
                value:
                  offers?.filter((el) => el.offerId === offerId)[0].price ||
                  price,
                currency: 'EUR',
              });
            } catch (error) {}
            // }

            // snaptr pixel event for add to cart
            try {
              //@ts-ignore
              snaptr('track', 'ADD_CART', {
                price:
                  offers?.filter((el) => el.offerId === offerId)[0].price ||
                  price,
                currency: 'EUR',
                item_ids: [product?.productSku ?? productSku],
                item_category: product?.productTypes[0] ?? '',
                number_items: qty,
              });
            } catch (error) {}

            // tiktok pixel event for add to cart
            // try {
            //   //@ts-ignore
            //   ttq.track('AddToCart', {
            //     value: offers.filter((el) => el.offerId === offerId)[0].price, // number. Value of the order or items sold. Example: 100.
            //     currency: 'EUR', // string. The 4217 currency code. Example: "USD".
            //     contents: [
            //       {
            //         content_id: product.productSku, // string. ID of the product. Example: "1077218".
            //         content_type: 'product', // string. Either product or product_group.
            //         content_name: product.name ?? '', // string. The name of the page or product. Example: "shirt".
            //         price: offers.filter((el) => el.offerId === offerId)[0].price, // number. The price of a single item. Example: 25.
            //       },
            //     ],
            //   });
            // } catch (error) {}
          }
        } catch (e) {}
      })
      .catch((err: AxiosError) => {
        throw Error(err.response?.data?.msg);
      });
    dispatch(fetchCarts(isEsim)).catch(() => {});
  };

export const addToCartByProductSKU =
  (productSKU: string, qty: number, product: any, price?: number) =>
  async (dispatch: any) => {
    await axios
      .post(
        apiConstants.server + '/public/cart/add-to-cart',
        {
          productSku: productSKU,
          qty: qty,
        },
        { withCredentials: true }
      )
      .then((response) => {
        try {
          sendRecombeeAddToCartEvent('add', qty, productSKU);
          dispatch(fetchCarts()).catch(() => {});
        } catch (e) {}
      })
      .catch((err: AxiosError) => {
        throw Error(err.response?.data?.msg);
      });
  };

export const addToMultipleCart =
  (
    products: {
      data: {
        image: string;
        mrp: number | null;
        offerId: number;
        platform: string;
        price: number;
        productId: number;
        regionId: number;
        regionName: string;
        slug: string;
        title: string;
        offersList: ProductOfferInterface[];
      };
      qty: number;
    }[]
  ) =>
  async (dispatch: any) => {
    try {
      await axios.post(
        apiConstants.server + '/public/cart/add-multiple',
        products.map((cur: any) => ({
          offerId: cur.data.offerId,
          qty: cur.qty,
        })),
        { withCredentials: true }
      );
    } catch (err: any) {
      throw Error(err?.response?.data?.msg);
    }
    dispatch(fetchCarts()).catch(() => {});
  };

/**
 * ak-TODO
 * shift the promo application logic to redux or context
 * @param code
 * @returns
 */
export const applyCouponRedux =
  ({
    promoCode,
    ccId,
    router,
    cleverTap,
  }: {
    promoCode: string;
    ccId: number | null;
    router: NextRouter;
    cleverTap: any;
  }) =>
  async (dispatch: any) => {
    try {
      const { data } = await axios.post(
        apiConstants.server + `/public/promo/apply`,
        {
          ccId: ccId,
          promoCode: promoCode,
        },
        { withCredentials: true }
      );
      if (!data.data.applied) {
        if (data.data.referredPromo) {
          return {
            referredPromo: data.data.referredPromo,
            oldCoupon: promoCode,
          };
        } else {
          WE_USER_EVENT_DISCOUNT_CODE_FAILED(
            {
              code: promoCode,
              failedMsg: data.data.msg,
            },
            cleverTap
          );
          throw Error(data.data.msg);
        }
      } else {
        WE_USER_EVENT_DISCOUNT_CODE_APPLIED(
          {
            code: promoCode,
            codeId: ccId?.toString() ?? '',
            codeName: promoCode,
          },
          cleverTap
        );
        saveEventV3({
          category: 'checkout',
          action: 'click',
          label: 'apply_coupon_button',
          properties: 'applied',
          value: [promoCode],
          from: router,
        });
        dispatch(fetchCarts())
          .then(() => {})
          .catch(() => {});
        if (
          // data.data.referredPromo &&
          Array.isArray(data.data.products) &&
          data.data.products.length > 0
        ) {
          return {
            referredPromo: data.data.referredPromo,
            // products: data.data.productsWithPromotionsMeta,
            products: data.data.productsWithPromotionsMetaObj,
            oldCoupon: promoCode,
          };
        }
      }
    } catch (error: any) {
      WE_USER_EVENT_DISCOUNT_CODE_FAILED(
        {
          code: promoCode,
          failedMsg: error.response.data.message,
        },
        cleverTap
      );
      saveEventV3({
        category: 'checkout',
        action: 'click',
        label: 'apply_coupon_button',
        properties: 'failed',
        value: [promoCode],
        from: router,
        jsonData: {
          error: error.response.data.message,
        },
      });
      throw Error(error?.response?.data?.message);
    }
  };

export const removeCouponRedux = () => async (dispatch: any) => {
  await axios
    .post(
      apiConstants.server + '/public/promo/remove',
      {},
      { withCredentials: true }
    )
    .then((response) => {
      dispatch(fetchCarts())
        .then(() => {})
        .catch(() => {});
    })
    .catch((error: AxiosError) => {
      throw Error(error?.response?.data?.msg);
    });
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState: cartInitialState,
  reducers: {
    fetchingCart: (state) => {
      state.isFetching = 'fetching';
    },
    setInitialCart: (state) => {
      state.cartId = null;
      state.cartItems = [];
      state.subTotal = null;
      state.serviceFee = null;
      state.total = null;
      state.paymentMethod = 1;
      state.promo = null;
      state.isFetching = 'not-fetched';
      state.subscription = false;
    },
    saveCart: (state, action) => {
      state.cartId = action.payload.cartId;
      let cartItems = action.payload.cartItems.map(
        (item: CartItemInterface, index: number) => {
          return {
            ...item,
            unreservedQty:
              item.unreservedQty === 0
                ? null
                : item.unreservedQty
                ? item.unreservedQty
                : state.cartItems.filter((el) => el.offerId === item.offerId)[0]
                    ?.unreservedQty ?? null,
          };
        }
      );

      state.cartItems = cartItems;
      state.serviceFee = action.payload.serviceFee;
      state.subTotal = action.payload.subTotal;
      state.total = action.payload.total;
      state.paymentMethod = action.payload.paymentMethod;
      state.promo = action.payload.promo;
      state.isFetching = 'fetched';
      state.subscription = action.payload.subscription;

      if (getSubscriptionABTestMethods() === 'yes') {
        state.drifflePlusPrice = action.payload.drifflePlusPrice;
      }
    },
  },
});

export const { saveCart, fetchingCart, setInitialCart } = cartSlice.actions;

export default cartSlice.reducer;
