import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
interface ModalState {
  userRegister: boolean;
  userLogin: boolean;
  validateEmailForm: boolean;
  validateEmailMessage: boolean;
  forgotPasswordForm: boolean;
  forgotPasswordMessage: boolean;
  rlcSelector: boolean;
  mobileSearchDialog: boolean;
  showReviewCartOOS: boolean;
  showPhoneOtp: boolean;
  openDialog: boolean;
  openEmailDialog: boolean;
  showTrustpilotWidget: boolean;
  tempEmail: string;
}

// Define the initial state using that type
const initialState: ModalState = {
  tempEmail: '',
  userRegister: false,
  userLogin: false,
  validateEmailForm: false,
  validateEmailMessage: false,
  forgotPasswordForm: false,
  forgotPasswordMessage: false,
  rlcSelector: false,
  mobileSearchDialog: false,
  showReviewCartOOS: false,
  showPhoneOtp: false,
  openDialog: true,
  openEmailDialog: false,
  showTrustpilotWidget: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    swapInLoginModalRedux: (state) => {
      state.userLogin = true;

      state.userRegister = false;
      state.validateEmailForm = false;
      state.validateEmailMessage = false;
      state.forgotPasswordForm = false;
      state.forgotPasswordMessage = false;
    },
    swapInRegisterModalRedux: (state) => {
      state.userRegister = true;

      state.userLogin = false;
      state.validateEmailForm = false;
      state.validateEmailMessage = false;
      state.forgotPasswordForm = false;
      state.forgotPasswordMessage = false;
    },
    openRegisterModalRedux: (state) => {
      state.userRegister = true;

      state.userLogin = false;
      state.validateEmailForm = false;
      state.validateEmailMessage = false;
      state.forgotPasswordForm = false;
      state.forgotPasswordMessage = false;
    },
    closeRegisterModalRedux: (state) => {
      state.userRegister = false;
    },
    showPhoneOtpRedux: (state) => {
      state.showPhoneOtp = true;
    },
    hidePhoneOtpRedux: (state) => {
      state.showPhoneOtp = false;
    },
    toCloseAllTheDialogs: (state) => {
      state.openDialog = false;
    },
    toOpenDialog: (state) => {
      state.openDialog = true;
    },
    showEmailDialog: (state) => {
      state.openEmailDialog = true;
    },
    closeEmailDialog: (state) => {
      state.openEmailDialog = false;
    },
    openLoginModalRedux: (state) => {
      state.userLogin = true;

      state.validateEmailForm = false;
      state.validateEmailMessage = false;
      state.userRegister = false;
      state.forgotPasswordForm = false;
      state.forgotPasswordMessage = false;
    },
    closeLoginModalRedux: (state) => {
      state.userLogin = false;
    },
    openValidateEmailMessageRedux: (state) => {
      state.validateEmailMessage = true;

      state.validateEmailForm = false;
      state.userLogin = false;
      state.userRegister = false;
      state.forgotPasswordForm = false;
      state.forgotPasswordMessage = false;
    },
    closeValidateEmailMessageRedux: (state) => {
      state.validateEmailMessage = false;
    },
    openValidateEmailFormRedux: (state) => {
      state.validateEmailForm = true;

      state.validateEmailMessage = false;
      state.userLogin = false;
      state.userRegister = false;
      state.forgotPasswordForm = false;
      state.forgotPasswordMessage = false;
    },
    closeValidateEmailFormRedux: (state) => {
      state.validateEmailForm = false;
    },
    openForgotPasswordFormRedux: (state) => {
      state.forgotPasswordForm = true;

      state.validateEmailMessage = false;
      state.validateEmailForm = false;
      state.userLogin = false;
      state.userRegister = false;
      state.forgotPasswordMessage = false;
    },
    closeForgotPasswordFormRedux: (state) => {
      state.forgotPasswordForm = false;
    },
    openForgotPasswordMessageRedux: (state) => {
      state.forgotPasswordMessage = true;

      state.validateEmailMessage = false;
      state.validateEmailForm = false;
      state.userLogin = false;
      state.userRegister = false;
      state.forgotPasswordForm = false;
    },
    closeForgotPasswordMessageRedux: (state) => {
      state.forgotPasswordMessage = false;
    },
    openRlcSelectorRedux: (state) => {
      state.rlcSelector = true;
    },
    closeRlcSelectorRedux: (state) => {
      state.rlcSelector = false;
    },
    openMobileSearchDialog: (state) => {
      state.mobileSearchDialog = true;
    },
    closeMobileSearchDialog: (state) => {
      state.mobileSearchDialog = false;
    },
    openShowReviewCartOOS: (state) => {
      state.showReviewCartOOS = true;
    },
    closeShowReviewCartOOS: (state) => {
      state.showReviewCartOOS = false;
    },
    closeTrustpilotWidget: (state) => {
      state.showTrustpilotWidget = false;
    },
    openTrustpilotWidget: (state) => {
      state.showTrustpilotWidget = true;
    },
    saveTempUserEmail: (state, action) => {
      state.tempEmail = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  closeLoginModalRedux,
  closeRegisterModalRedux,
  openLoginModalRedux,
  openRegisterModalRedux,
  swapInLoginModalRedux,
  swapInRegisterModalRedux,
  showPhoneOtpRedux,
  hidePhoneOtpRedux,
  toOpenDialog,
  toCloseAllTheDialogs,
  openValidateEmailMessageRedux,
  closeValidateEmailMessageRedux,
  openValidateEmailFormRedux,
  closeValidateEmailFormRedux,
  openForgotPasswordFormRedux,
  closeForgotPasswordFormRedux,
  openForgotPasswordMessageRedux,
  closeForgotPasswordMessageRedux,
  openRlcSelectorRedux,
  closeRlcSelectorRedux,
  openMobileSearchDialog,
  closeMobileSearchDialog,
  openShowReviewCartOOS,
  closeShowReviewCartOOS,
  showEmailDialog,
  closeEmailDialog,
  closeTrustpilotWidget,
  openTrustpilotWidget,
  saveTempUserEmail,
} = modalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.count;

export default modalSlice.reducer;
